import logo from "./logo.svg";
import "./App.css";
import Navbar from "./containers/Navbar/Navbar";
import Header from "./containers/Header/Header";
import TextAnimation from "./containers/TextAnimation/TextAnimation";
import Sales from "./containers/Sales/Sales";
import GetStarted from "./containers/GetStarted/GetStarted";
import Pricing from "./containers/Pricing/Pricing";
import Faq from "./containers/Faq/Faq";
import Demo from "./containers/Demo/Demo";
import FooterComponent from "./containers/Footer/Footer";

import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Ethics from "./containers/Ethics/Ethics";
import Privacy from "./containers/Privacy/Privacy";
import Cancellation from "./containers/Cancellation/Cancellation";
import Shipping from "./containers/Shipping/Shipping";
import AboutUs from "./containers/AboutUs/AboutUs";
import Careers from "./containers/Careers/Careers";
import ContactUs from "./containers/ContactUs/ContactUs";
import UseCase from "./components/UseCase/UseCase";
import { Event, Marketing, ReEngage } from "./constants/UseCaseData";
import DemoForm from "./containers/Form/Form";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="App">
        <Navbar />
        <Header />
        <TextAnimation />
        <Sales />
        <GetStarted />
        <Pricing />
        <Faq />
        <Demo modelname="Text-to-Speech API"/>
        <FooterComponent />
      </div>
    ),
  },
  {
    path: "/ethics",
    element: (
      <div className="App">
        <Navbar />
        <Ethics />
        <FooterComponent />
      </div>
    ),
  },
  {
    path: "/aboutus",
    element: (
      <div className="App">
        <Navbar />
        <AboutUs />
        <FooterComponent />
      </div>
    ),
  },
  {
    path: "/careers",
    element: (
      <div>
        <Navbar />
        <Careers />
        <FooterComponent />
      </div>
    ),
  },
  {
    path: "/contactus",
    element: (
      <div>
        <Navbar />
        <ContactUs />
        <FooterComponent />
      </div>
    ),
  },
  {
    path: "/usecase/d2c",
    element: (
      <div>
        <Navbar />
        <UseCase content={ReEngage} />
        <FooterComponent />
      </div>
    ),
  },
  ,
  {
    path: "/usecase/stakeholder",
    element: (
      <div>
        <Navbar />
        <UseCase content={Event} />
        <FooterComponent />
      </div>
    ),
  },
  {
    path: "/usecase/marketing",
    element: (
      <div>
        <Navbar />
        <UseCase content={Marketing} />
        <FooterComponent />
      </div>
    ),
  },
  {
    path: "/form",
    element: (
      <div>
        <Navbar />
        <DemoForm />
        <FooterComponent />
      </div>
    ),
  },
  {
    path: "/privacy",
    element: (
      <div>
        <Navbar />
        <Privacy />
        <FooterComponent />
      </div>
    ),
  },
  {
    path: "/shipping",
    element: (
      <div>
        <Navbar />
        <Shipping />
        <FooterComponent />
      </div>
    ),
  },
  {
    path: "/cancellation",
    element: (
      <div>
        <Navbar />
        <Cancellation />
        <FooterComponent />
      </div>
    ),
  },
]);

export default router;

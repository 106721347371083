import React from 'react'
import './Ethics.css'

function Ethics() {
    return (
        <div>
            <div className='ethics-first-section'>
                <h1 className='ethics-first-section-heading'>Responsible Use of SeiSei.ai</h1>
                <p className='ethics-first-section-paragraph'>At SeiSei.ai, we are committed to the ethical use of technology and the responsible creation of synthetic videos for personal interests. This Ethics Policy outlines our principles and practices to ensure the protection of consumer data, privacy, and ownership of personal avatars generated on our platform. By using our platform, you agree to adhere to these ethical guidelines.</p>
            </div>
            <div className='ethics-second-section'>
                <section>
                    <div>
                        <h1>1. Ownership of Personal Avatars:</h1>
                        <p>We respect your ownership of the avatars you create using our platform: User Ownership: Any avatars generated by users on our platform belong exclusively to the user who created them. We do not claim ownership of these avatars. Consent for Sharing: Any sharing of personal avatars with others is solely the user's responsibility and choice. We do not share avatars or their data without explicit consent from the user.</p>
                    </div>
                    <div>
                        <h1>3. Consent and Explicit Use:</h1>
                        <p>Your personal data and avatar generation are subject to your explicit consent: User Consent: We seek your explicit consent before using your data for any purpose other than avatar generation, and we will always respect your choices. Customization: You have the right to customize and control the generated avatars based on your preferences. We do not alter or use your avatars without your consent.</p>
                    </div>
                    <div>
                        <h1>5. User Rights:</h1>
                        <p>Your rights as a user are paramount to us:Access and Deletion: You have the right to access and delete any personal data and avatars associated with your account. Withdraw Consent: You can withdraw your consent for data usage and avatar creation at any time. Upon withdrawal, we will cease using your data for these purposes.</p>
                    </div>
                </section>
                <section>
                    <div>
                        <h1>2. Data Privacy:</h1>
                        <p>We are dedicated to safeguarding your data and privacy: User Data Protection: We employ advanced security measures to protect your personal information, contacts, names, and recorded data from unauthorized access, loss, or disclosure. Data Usage: Any data shared with us, including contacts, names, and recorded content, is used solely to improve the avatar generation experience. Your data will never be used for any purpose other than what you consent to. Data Transparency: We provide clear explanations of the types of data we collect, how it is used, and your rights regarding your personal information. Our Privacy Policy provides more detailed information.</p>
                    </div>

                    <div>
                        <h1>4. Responsible Use:</h1>
                        <p>We emphasize the responsible and ethical use of our platform: Legal and Ethical Use: You are responsible for ensuring that the avatars you generate and share comply with all applicable laws and ethical standards. Misuse Prevention: Any use of avatars for malicious, harmful, or unethical purposes is strictly prohibited. We will take appropriate actions against such misuse.</p>
                    </div>
                </section>
            </div>

            <div className='ethics-third-section'>At SeiSei.ai, we are dedicated to providing you with the ability to create personalized avatars while respecting your data privacy and ownership. This Ethics Policy reflects our commitment to these values. By using our synthetic video platform, you acknowledge and agree to follow these ethical guidelines. For any questions, or concerns, or to report ethical violations, please fill out this form. SeiSei.ai https://www.seisei.ai/</div>
        </div>
    )
}

export default Ethics

import React from "react";
import "./Shipping.css";

function Shipping() {
  return (
    <div className="shipping-second-section">
      <center>
        <h2>SHIPPING AND DELIVERY POLICY</h2>
        <h3>With effect from 01 May, 2024</h3>
      </center>
      <br />
      <p>
        <b>1. Delivery Method</b>
      </p>
      <br />
      <ul>
        <li>
          All personalized videos and avatars will be delivered digitally
          through the client's registered email or made available for download
          in the client's account on our platform.
        </li>
        <li>
          There are no physical goods to be shipped until unless a custom order
          on a large scale is placed by the client.
        </li>
      </ul>
      <br />
      <p>
        <b>2. Delivery Time</b>
      </p>
      <br />
      <ul>
        <li>
          Standard delivery time for digital products is typically within 24-48
          hours of order confirmation.
        </li>
        <li>
          For bulk or custom orders, delivery time may vary based on the
          complexity and volume of the order. Clients will be informed of the
          expected delivery time at the time of order confirmation.
        </li>
        <li>
          If a delay is anticipated, clients will be notified promptly with an
          updated delivery timeframe.
        </li>
      </ul>
      <br />
      <p>
        <b>3. Delivery Confirmation</b>
      </p>
      <br />
      <ul>
        <li>
          Clients will receive a confirmation email with download instructions
          once the digital product is ready. This email may contain links to
          download the products securely.
        </li>
        <li>
          Clients should check their spam or junk folder if they do not see the
          confirmation email in their inbox.
        </li>
        <li>
          In case of any issues with the delivery, clients should contact our
          customer support team immediately via email at [support@seisei.ai] or
          by calling [+91 9933996265].
        </li>
        <li>
          We will assist in troubleshooting the issue and ensure the client
          receives their products as soon as possible.
        </li>
      </ul>
      <br />
      <p>
        <b>4. Non-Delivery</b>
      </p>
      <br />
      <ul>
        <li>
          If a digital product is not received within the specified delivery
          time, clients must notify our customer support team within 7 days of
          the expected delivery date to investigate the issue.
        </li>
        <li>
          Non-delivery claims made after 7 days may not be eligible for
          compensation or reshipment.
        </li>
      </ul>
      <br />
      <p>
        <b>5. Data Security</b>
      </p>
      <br />
      <ul>
        <li>
          We ensure that all digital deliveries are secure and free from any
          malware or viruses. Clients are advised to keep their login
          credentials secure and to download the products from trusted devices.
        </li>
        <li>
          Any misuse of the downloaded content, such as unauthorized
          distribution or sharing, is strictly prohibited and may result in
          legal action.
        </li>
      </ul>
      <br />
      <p>
        <b>6. Amendments</b>
      </p>
      <br />
      <ul>
        <li>
          We reserve the right to amend this policy at any time. Any changes
          will be posted on our website and will be effective immediately.
          Clients are encouraged to review the policy periodically for any
          updates.
        </li>
      </ul>
    </div>
  );
}

export default Shipping;

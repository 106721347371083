import React from "react";
import "./Cancellation.css";

function Cancellation() {
  return (
    <div className="cancellation-second-section">
      <center>
        <h2>CANCELLATION AND REFUND POLICY</h2>
        <h3>With effect from 01 May, 2024</h3>
      </center>
      <br />
      <p>
        <b>1. Cancellation by Client</b>
      </p>
      <br />
      <ul>
        <li>
          Clients may cancel their orders within 2 hours of placing the order
          without incurring any charges.
        </li>
        <li>
          To cancel an order, clients must contact our customer support team via
          email at [support@seisei.ai] or by calling [+91 9933996265].
        </li>
        <li>
          Cancellations requested after 2 hours from the order placement may not
          be eligible for a full refund and will be reviewed on a case-by-case
          basis.
        </li>
      </ul>
      <br />
      <p>
        <b>2. Cancellation by Company</b>
      </p>
      <br />
      <ul>
        <li>
          We reserve the right to cancel any order due to technical issues,
          suspected fraud, or other unforeseen circumstances. In such cases,
          clients will be notified via email or phone, and a full refund will be
          issued within a specific timeframe.
        </li>
        <li>
          Orders may also be canceled if the client fails to provide necessary
          information or appropriate materials required for completing the order
          within the specified timeframe.
        </li>
      </ul>
      <br />
      <p>
        <b>3. Refund Process</b>
      </p>
      <br />
      <ul>
        <li>
          Refunds will be processed within 7-10 business days from the date of
          cancellation approval.
        </li>
        <li>
          Refunds will be credited to the original method of payment. Clients
          will receive a confirmation email once the refund has been processed.
        </li>
        <li>
          In cases where a partial refund is issued, the amount will be based on
          the portion of the service that has not yet been rendered.
        </li>
      </ul>
      <br />
      <p>
        <b>4. Non-Refundable Services</b>
      </p>
      <br />
      <ul>
        <li>
          Any services already rendered, including partially completed video
          processing and customization, are non-refundable in nature.
        </li>
        <li>
          Subscription fees and any other recurring charges are non-refundable
          unless explicitly stated otherwise in the subscription terms.
        </li>
      </ul>
      <br />
      <p>
        <b>5. Custom Orders</b>
      </p>
      <br />
      <ul>
        <li>
          Custom orders or large-scale projects may have specific cancellation
          terms outlined in the service agreement. Clients will be informed of
          these terms before order confirmation.
        </li>
        <li>
          Deposits or advance payments made for custom orders are typically
          non-refundable unless otherwise specified in the agreement.
        </li>
      </ul>
      <br />
      <p>
        <b>6. Amendments</b>
      </p>
      <br />
      <ul>
        <li>
          We reserve the right to amend this cancellation policy at any time.
          Any changes will be posted on our website and will be effective
          immediately. Clients are encouraged to review the policy periodically
          for any updates.
        </li>
      </ul>
    </div>
  );
}

export default Cancellation;

import React from "react";
import "./Privacy.css";

function Privacy() {
  return (
    <div className="privacy-second-section">
      <center>
        <h2>PRIVACY POLICY</h2>
      </center>
      <br />
      <p>
        <b>With effect from 01 May, 2024</b>
      </p>
      <br />
      <p>
        Welcome to SeiSei.ai (the “Platform”). This Platform and its suite of
        products and services are owned and operated by Rgen AI Technologies
        Private Limited, having its registered office at Ward No. 41, Jat
        Colony, Navalgarh Road, Sikar Rajasthan 332001 (hereinafter referred to
        as the “Company” or “us” or “we” or “our”, which expression shall mean
        and include its officers, successors and permitted assigns). The Company
        has developed innovative AI Models that allows users to seamlessly
        convert their text & audio into realistic, personalized videos. Whether
        you're a content creator, marketer, educator, or simply looking to add a
        personal touch to your messages, our platform provides the tools you
        need to bring your ideas to life visually. With our user-friendly
        interface and advanced features, the user can customize every aspect of
        his/her video, from the visuals to the voiceover, ensuring that their
        message resonates authentically with his/her audience. Plus, our
        cutting-edge technology ensures high-quality output that rivals
        professional productions, all without the need for expensive equipment
        or technical expertise.
      </p>
      <br />
      <p>
        The Services may be provided by the Company through the Platform, and
        its suite of products and services, as may be identified by the Company
        from time to time. This Privacy Policy (“Privacy Policy”) sets out the
        Company's privacy practices with respect to the entire scope of Services
        provided by the Company. This document is published in accordance with
        the provisions of the Information Technology Act, 2000, and the rules,
        advisory or any other notifications made thereunder that require
        publishing the rules and regulations, privacy policy, and terms of use
        on an online portal of the Company. We request you to go through this
        Privacy Policy and the Terms and Conditions carefully before you decide
        to access this Platform.
      </p>
      <br />
      <p>
        For the purposes of this Privacy Policy, the words “us”, “we”, and “our”
        refer to the Company and all references to “you”, “your” or “user”, as
        applicable mean the person who accesses, uses and/or participates in the
        Platform in any manner or capacity. The Company is strongly committed to
        protecting the privacy of its users and has taken all necessary and
        reasonable measures to protect the confidentiality of the user
        information and its transmission through the internet.{" "}
      </p>
      <br />
      <p>
        <b>General Terms:</b>
      </p>
      <br />
      <ul>
        <li>
          The Company shall not be held liable for disclosure of any information
          if such disclosure is in accordance with this Privacy Policy, the
          Terms of Use, and/or applicable law.
        </li>
        <li>
          By accessing or using the Platform, or by otherwise giving us your
          personal or public information, you confirm that you have the capacity
          to enter into a legally binding contract under the laws of India, and
          have read, understood, and agreed to the practices and policies
          outlined in this Privacy Policy and agree to be bound by the Privacy
          Policy.
        </li>
        <li>
          You hereby consent to our collection, use, sharing, and disclosure of
          your information as described in this Privacy Policy. We reserve the
          right to change, modify, add, or delete portions of the terms of this
          Privacy Policy, at our sole discretion, at any time, and any continued
          use of the Platform, following any such amendments to the Privacy
          Policy, will be deemed as an implicit acceptance of the Privacy Policy
          in its amended form. You are requested to review the Privacy Policy
          from time to time to keep yourself updated with any changes, or
          modifications made to the terms hereof.
        </li>
        <li>
          If you are accessing or using this Platform from an overseas location,
          you do so at your own risk and shall be solely liable for compliance
          with any applicable local laws.
        </li>
        <li>
          If you do not agree with any of the terms and conditions of this
          Privacy Policy, please do not proceed further to use this Platform.
          This Privacy Policy is subject to change at any time without notice.
          To make sure you are aware of any changes, please review this policy
          on this Platform periodically.
        </li>
        <li>
          If you object to your information being transferred or used in
          accordance with this Privacy Policy, please do not use the Platform.
        </li>
      </ul>
      <br />
      <p>
        All capitalized terms used but not defined herein shall have the meaning
        ascribed to in the Terms of Use.
      </p>
      <br />
      <p>
        <b>1. Information Collection</b>
      </p>
      <br />
      <ul>
        <li>
          You (i.e. the User) are the sole owner of your (i.e. User’s)
          information. The Company just collects your information through the
          Platform for providing the products and services you have signed up
          for. We will not sell, share, transfer, or rent any personal
          information to others in ways different from what is disclosed in this
          privacy policy and the Terms of Use.
        </li>
        <li>
          The Company collects information from you on the Register/Log-in page
          of the Platform. On the signup page, you are required to give your
          personally identifiable contact information (such as name, mobile
          number, email ID, etc.). A verification process is used to confirm
          your identity through the contact information that you provide. When
          the Platform requests your identity, the Platform will clearly
          indicate the purpose of the inquiry before the information is
          requested.
        </li>
        <li>
          We collect personal data because (a) it allows us to provide with you
          the Service offered through the Website, App, or other channels; (b)
          it allows us to respond to questions, feedbacks, or requests you or
          others may have about the Service or the information provided to you
          or others on or through the Service; (c) it helps us improve the
          Service and the information provided from or through the Service; and
          (d) it allows us, with your consent, to post your comments and
          annotations and related information, and to provide other useful
          information on the Service. In addition, your personal data helps us
          communicate to you through the Service, as well as to provide
          additional related information and events about which you might like
          to hear. We also may correlate your personal data with information
          from other sources, including personal data we have obtained from or
          about you through our Service.
        </li>
        <li>
          Every computer/mobile device connected to the Internet is given a
          domain name and a set of numbers that serve as that computer’s
          Internet Protocol or “IP” address. When you request a page from any
          page within the Company platform, our web servers automatically
          recognize your domain name and IP address. The domain name and IP
          address reveal nothing personal about you other than the IP address
          from which you have accessed the Platform.
        </li>
      </ul>
      <br />
      <p>
        <b>2. Children’s And Minor’s Privacy</b>
      </p>
      <br />
      <p>
        The Company strongly encourages parents and guardians to supervise the
        online activities of their minor children and consider using parental
        control tools available from online services and software manufacturers
        to help provide a child-friendly online environment. These tools also
        can prevent minors from disclosing their name, address, and other
        personally identifiable information online without parental permission.
        Although the Platform is not intended for use by minors, the Company
        respects the privacy of minors who may inadvertently use the Internet or
        the Platform.
      </p>
      <br />
      <p>
        <b>3. Data Retention</b>
      </p>
      <br />
      <p>
        Subject to the other terms contained in this Privacy Policy and the
        Terms of Use, your information will be retained with the Company until
        you specifically request the Company to destroy such information by way
        of a written request through an official mail at{" "}
        <a href="" style={{ color: "#1357cc", textDecoration: "underline" }}>
          &nbsp;support@seisei.ai
        </a>{" "}
        . The Company shall, upon receipt of such request from you, proceed to
        delete such information after carrying on internal verification
        procedures. Notwithstanding the foregoing, the Company will retain and
        use your information as necessary to comply with its legal obligations,
        resolve disputes, carry on audits, and enforce its agreements or for
        other business purposes.
      </p>
      <br />
      <p>
        <b>4. Opt-Out Procedures</b>
      </p>
      <br />
      <p>
        Upon initial communication from the Company, you may opt out of
        receiving further communications from the Company. To be completely
        removed from the Company subscription list, you may contact us at
        <a href="" style={{ color: "#1357cc", textDecoration: "underline" }}>
          &nbsp;support@seisei.ai
        </a>
        . If you are using an e-mail forwarding service or other similar service
        please make sure to include the correct e-mail address or addresses.
      </p>
      <br />
      <p>
        <b>5. Company as a Pass-Through Facilitator</b>
      </p>
      <br />
      <p>
        The Company may be a pass-through facilitator for data under a contract
        with a client or partner (“Enterprise Client”). In such cases, the
        Company shall not be deemed to be the owner of such information. The
        Company may, as part of the agreement with such Enterprise Clients, use
        the information collected from such Enterprise Clients to provide the
        relevant services to the user. The Company may continue to use the data
        for the purposes identified in this statement and the Terms of Use.
        <br /> Please note however, that in respect of any information received
        by the Company under certain specific client support program: (a) the
        Company shall retain identified data for a period of 1 (One) year from
        the date of termination of such agreement, for performance of its
        contractual obligations, audits, diligence thereunder; and (b) the
        Company shall thereafter be permitted to retain only de-identified data
        for such period of time as may be necessary. The de-identified data at
        any point in time will be used for the purpose of compliance with
        applicable law, carrying on its contractual obligations, for carrying on
        audits and improvement of its technology, and strengthening its AI
        engine. All personal identification data will be deleted after a period
        of 1 (One) year from the date of termination of the agreement.
      </p>
      <br />
      <p>
        <b>6. Use of the Information Collected</b>
      </p>
      <br />
      <p>
        <i>6.1 Use of Information for Services</i>
      </p>
      <p>
        The primary goal of the Company in collecting the information is to
        provide you the Services as defined in the Terms of Use. The Company may
        use the personal and non-personal information provided by you, including
        but not limited to, the following ways:
      </p>
      <ul>
        <li>Identify and reach you;</li>
        <li>
          Resolve technical issues of access to the Platform via telephone or
          email;
        </li>
        <li>Provide you with further information, products and services;</li>
        <li>Better understand of users’ needs and interests;</li>
        <li>Personalize your experience and customize experience;</li>
        <li>Run statistical research and undertake scientific publications;</li>
        <li>
          Improving the product and services offered to current and future users
          and partners by improving parts of the Platform, including but not
          limited to the algorithms, logic systems, content, decision support,
          engineering, and artificial intelligence;
        </li>
        <li>
          Deliver targeted advertisements, promotional messages, notices and
          other information related to the Service and your interests;
        </li>
        <li>
          Notify you about changes, updates, and other announcements related to
          the Service and our other products and services;
        </li>
        <li>
          Providing data to clients or partners under the terms of agreement
          with them;
        </li>
        <li>
          For carrying on audits, due diligence for effecting investments in the
          company
        </li>
        <li>
          Detect and protect us against error, fraud, and other criminal
          activity;
        </li>
        <li>Make disclosures as may be required under applicable law.</li>
        <li>Improve our Platform in order to better serve the user;</li>
        <li>
          Allow us to better service the user in responding to customer service
          requests;
        </li>
        <li>Administer a contest, promotion, survey or other site feature;</li>
        <li>
          Provide access to other users as part of a social feed, in order to
          provide motivation or assistance to such other users;
        </li>
      </ul>
      <br />
      <p>
        <i>6.2 Sale of Assets, Merger, Acquisition</i>
      </p>
      <p>
        The Company may share your information, including your information with
        our parent, subsidiaries and affiliates for internal purposes. The
        Company also reserves the right to disclose and transfer all such
        information: (i) to a subsequent owner, co-owner or operator of the
        Platform, Website or applicable database; or (ii) in connection with a
        corporate merger, consolidation, restructuring, the sale of
        substantially all of our membership interests and/or assets or other
        corporate change, including, during the course of any due diligence
        process. You will be notified via email and/or a prominent notice on our
        Platform and/ or Website of any change in ownership or uses of your
        personal information, as well as any choices you may have regarding your
        personal information.
      </p>
      <br />
      <p>
        <i>6.3 Cookies</i>
      </p>
      <p>
        Cookies are small pieces of information saved by your browser onto your
        computer/mobile. Cookies are used to record various aspects of your
        visit and assist the Company in providing you with uninterrupted
        Service. The Company does not use cookies to save personal information
        for unauthorized use.
      </p>
      <br />
      <p>
        <i>6.4 Ownership of work Products</i>
      </p>
      <p>
        Any information provided by you through the Platform may be utilized by
        the Company for carrying on data analytics, derivatives, and other work
        products. The Company shall be deemed to be the sole owner of any and
        all such data analytics, derivatives, and work products, and may use all
        such data analytics, derivatives and work products for each of the
        purposes identified in this Privacy Policy and the Terms of Use.
      </p>
      <br />
      <p>
        <i>6.5 Non-disclosure of Information</i>
      </p>
      <p>
        Subject to the provisions of this Privacy Policy and the Terms of Use,
        the Company pledges that it will not sell or rent your personal details
        to anyone and your personal information will be protected and maintained
        strictly confidential by the Company except in the following cases:
      </p>
      <ul>
        <li>
          The Company may disclose your personal information in the event it is
          required to do so by law, rule, regulation, law, enforcement,
          governmental official, legal or regulatory authorities and, or, to
          such other statutory bodies who have appropriate authorization to
          access the same for any specific legal purposes;
        </li>
        <li>
          The Company may disclose your information in order to provide you the
          Services, enforce or apply the Terms of Use, or to protect the rights,
          property or safety of the Company, its users or others. This includes
          exchanging information with other companies/agencies that work for
          fraud prevention;
        </li>
        <li>
          The Company may disclose your information to such third parties to
          whom it transfers its rights and duties under any agreement entered
          into with such third parties;
        </li>
        <li>
          The Company may disclose your information to any of its affiliates or
          related entities;
        </li>
      </ul>
      <br />
      <p>
        <b>7. Sharing of Information</b>
      </p>
      <br />
      <p>
        <i>7.1 Sharing</i>
      </p>
      <p>
        The Company may share aggregated personal information with the Company’s
        partners/clients as per the terms of any agreement with such
        partner/client. The data shared may be on an identified or unidentified
        basis, based on the terms agreed by the Company with such
        partner/client. Information available with the Company may be shared by
        the Company even after completion of the Services. The Company shall not
        be liable for the transfer of any personal identification information
        resulting from the loss or distribution of data, the delineation or
        corruption of storage media, power failures, natural phenomena, riots,
        acts of vandalism, sabotage, terrorism or any other event beyond the
        Company’s control.
      </p>
      <br />
      <p>
        <i>7.2 Consulting and Sub Contracting</i>
      </p>
      <p>
        The Company may sub-contract all or part of the Services to a
        third-party sub-contractor, or partner with another party to provide
        specific services. When you sign up for these services, the Company will
        share names, or other contact information that is necessary for the
        third party to provide these services. Per the Company’s contractual
        arrangements with parties, these parties are not allowed to use
        personally identifiable information except for the explicit purpose of
        providing these services.
      </p>
      <br />
      <p>
        <b>8. Spam</b>
      </p>
      <br />
      <p>
        The Company maintains a strict “No-Spam” policy, which means that the
        Company does not intend to sell, rent, or otherwise give your e-mail
        address to a third party without your consent.
      </p>
      <br />
      <p>
        <b>9. Exclusion</b>
      </p>
      <br />
      <ul>
        <li>
          This Privacy Policy does not apply to any information other than
          information collected by the Company via any means, including the
          Platform, including such information collected in accordance with the
          clause on “Use of the Information Collected” above. This Privacy
          Policy will not apply to any unsolicited information provided by you
          through this Platform or through any other means. This includes but is
          not limited to, information posted on any public areas of the
          Platform. All such unsolicited information shall be deemed to be
          non-confidential and the Company will be free to use, and disclose
          such unsolicited information without limitation.
        </li>
        <li>
          The Company also protects your personal information offline other than
          as specifically mentioned in this Privacy Policy. Access to your
          personal information is limited to employees, agents, consultants or
          partners and third parties, who the Company reasonably believes will
          need that information to enable the Company to provide Services to
          you. The Company will make best efforts to ensure that your personal
          contact information is protected. However, the Company is not
          responsible for the confidentiality, security or distribution of your
          personal information by our partners and third parties outside the
          scope of our agreement with such partners and third parties.
        </li>
      </ul>
      <br />
      <p>
        <b>10. Confidentiality</b>
      </p>
      <br />
      <p>
        The Company takes all necessary precautions to protect your personal
        information both online and offline. No administrator at the Company
        will have knowledge of your password.
      </p>
      <br />
      <p>
        <i>10.1 Third-party Links</i>
      </p>
      <p>
        The Platform may contain links to other sites, products, platforms and
        services. These are not necessarily under the control of the Company.
        Please be aware that the Company is not responsible for the privacy
        practices of such other sites. The Company encourages you to read the
        privacy policies of each and every website that collects personally
        identifiable information. If you decide to access any of the third-party
        sites linked to the Platform, you do this entirely at your own risk. Any
        links to any partner of the Platform should be the responsibility of the
        linking party, and the Company shall not be responsible for notification
        of any change in name or location of any information on the Platform.
        You further acknowledge and agree that we are not liable for any loss or
        damage which may be incurred by you as a result of the collection and/or
        disclosure of your information via Third Party Links, as a result of any
        reliance placed by you on the completeness, accuracy or existence of any
        advertising, products services, or other materials on, or available via
        such Third Party Links. This will include all transactions, and
        information transmitted therein, between you and any such third-party
        sites or applications or resources, such transactions are strictly
        bi-partite. We shall not be liable for any disputes arising from or in
        connection with such transactions between you and the aforementioned
        third parties. We recommend that you exercise reasonable diligence, as
        you would in traditional offline channels, and practice judgment and
        common sense before committing to any transaction or exchange of
        information, including but not limited to reviewing the third-party
        website or application’s privacy policy.
      </p>
      <br />
      <p>
        <b>11. Notification of Changes</b>
      </p>
      <br />
      <ul>
        <li>
          We may update this Privacy Policy at any time, with or without advance
          notice. In the event there are significant changes in the way we treat
          your personally identifiable information, or in the Privacy Policy
          document itself, we will display a notice on the Company website or
          send you a notification or update of the same through electronic means
          either within or outside the Platform, so that you may review the
          changed terms. As always, if you object to any of the changes to our
          terms, and you no longer wish to use the Platform, you may contact
          <a href="" style={{ color: "#1357cc", textDecoration: "underline" }}>
            &nbsp;support@seisei.ai
          </a>{" "}
          to deactivate your account. Unless stated otherwise, our current
          Privacy Policy applies to all information that the Company has about
          you and your account.
        </li>
        <li>
          Using the Company Services or accessing the Platform after a notice of
          changes has been sent to you or published on our website shall
          constitute your consent to the changed terms.
        </li>
      </ul>
      <br />
      <p>
        <b>12. Grievance Officer</b>
      </p>
      <br />
      <p>
        If you have any grievance with respect to the Platform or the Services,
        including any discrepancies and grievances with respect to the
        processing of information, you can contact our Grievance Officer at:
        <br />
        Name: Rajat, E-mail:{" "}
        <a href="" style={{ color: "#1357cc", textDecoration: "underline" }}>
          &nbsp;rajat@seisei.ai
        </a>{" "}
        <br />
        <br />
        The Grievance Officer shall redress your grievances expeditiously,
        within 1 (one) month from the date of receipt of the grievance. Except
        where required by law, the Company cannot ensure a response to questions
        or comments regarding topics unrelated to this policy or the Company’s
        privacy practices.
      </p>
      <br />
      <p>
        <b>13. Contact Person in Data Protection Matters</b>
      </p>
      <br />
      <p>
        If you have any questions regarding the processing of your personal
        data, please contact us via the following:
        <br />
        Data Protection Officer: Rashmi, E-mail:{" "}
        <a href="" style={{ color: "#1357cc", textDecoration: "underline" }}>
          &nbsp;rashmi@seisei.ai
        </a>
      </p>
      <br />
      <p>
        <b>14. Consent</b>
      </p>
      <br />
      <p>
        By consenting to the terms under this Privacy Policy, you hereby provide
        express consent to the Company to collect, share, transfer, store,
        retain, disseminate or use the information collected by the Company from
        your usage of the Platform in accordance with the terms of the Privacy
        Policy. The Company will continue to retain information provided by you
        until you specifically request the Company to destroy such information.
        You may, at any time, withdraw consent for the collection or processing
        of any information provided by you, by sending an email to
        <a href="" style={{ color: "#1357cc", textDecoration: "underline" }}>
          &nbsp;support@seisei.ai
        </a>
        . Upon verification of such request, the Company may, subject to its
        obligations pursuant to law, destroy all information provided by you
        from its servers. However, please note that the Company shall continue
        to retain a copy of the information provided by you for the purpose of
        carrying out periodic audits. In such a case, the Company shall also
        instruct its partners/ clients to delete any copy of your information.
        However, the Company shall not have the obligation to validate or
        enforce any such deletion by the partner/client.
      </p>
      <br />
      <p>
        <b>15. Miscellaneous</b>
      </p>
      <br />
      <ul>
        <li>
          <i>Indemnity:</i> You agree and undertake to indemnify us in any suit
          or dispute by any third party arising out of disclosure of information
          by you to third parties either through our Platform or otherwise and
          your use and access of websites, applications and resources of third
          parties. We assume no liability for any actions of third parties with
          regard to your personal information which you may have disclosed to
          such third parties.
        </li>
        <li>
          <i>Severability:</i> Each clause of this Privacy Policy shall be and
          remain separate from and independent of and severable from all and any
          other clauses herein except where otherwise expressly indicated or
          indicated by the context of the Privacy Policy. The decision or
          declaration that one or more clauses are null and void shall have no
          effect on the remaining clauses of this Privacy Policy.
        </li>
        <li>
          <i>Jurisdiction of Court:</i> This Privacy Policy along with Terms of
          Use shall be governed by the Laws of India. The Courts of Law at
          Jaipur (Rajasthan) shall have exclusive jurisdiction over any disputes
          arising under this Privacy Policy along with the Terms of Use.
        </li>
        <li>
          <i>Restriction of Liability:</i> We make no claims, promises or
          guarantees about the accuracy, completeness, or adequacy of the
          contents available through the Platform and expressly disclaims
          liability for errors and omissions in the contents available through
          the Platform.
        </li>
      </ul>
      No warranty of any kind, implied, expressed or statutory, including but
      not limited to the warranties of non-infringement of third-party rights,
      title, merchantability, fitness for a particular purpose and freedom from
      computer virus, is given with respect to the contents available through
      the Platform or its links to other internet resources as may be available
      to you through the Platform.
      <br />
      Reference in the Platform to any specific commercial products, processes,
      or services, or the use of any trade, firm or corporation name is for the
      information and convenience of the public and does not constitute
      endorsement, recommendation, or favoring by us.
      <br />
      <br />
      <p>
        You have read this Privacy Policy and agree to all of the provisions
        contained above.
      </p>
    </div>
  );
}

export default Privacy;

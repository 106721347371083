import React, { useEffect, useState } from "react";
import "./Demo.css";
import { Col, Row } from "antd";
import axios from "axios";
import { Loader } from "rsuite";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, push, set } from "firebase/database";

function Demo(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [language, setLanguage] = useState("en");
  const [textInput, setTextInput] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [db, setDb] = useState(null);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyC8X6faC79gzDtfTNRjgsmgW0TASA8zuR4",
      authDomain: "seisei-67bfb.firebaseapp.com",
      projectId: "seisei-67bfb",
      storageBucket: "seisei-67bfb.appspot.com",
      messagingSenderId: "269990168117",
      appId: "1:269990168117:web:dea235f08c92a53045937c",
      measurementId: "G-H0LDGPVKCM",
      databaseURL: "https://seisei-67bfb-default-rtdb.asia-southeast1.firebasedatabase.app",
    };

    const app = initializeApp(firebaseConfig);
    const realtimeDB = getDatabase(app);

    setDb(realtimeDB);
  }, []);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleTextInputChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "audio/wav") {
      setAudioFile(file);
    } else {
      alert("Please upload a valid .wav file.");
    }
  };

  const handleUrlChange = (e) => {
    const url = e.target.value;
    setAudioUrl(url);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!db) {
      console.error("Firestore db is not initialized!");
      setLoading(false);
      return;
    }

    try {
      const x = new Date().getTime();
      const userRef = ref(db, "users/" + x);
      const userId = push(userRef).key;
      set(userRef, {
        name: name,
        email: email,
        input: textInput,
      });
      console.log("User added to Firestore with ID: ", userId);
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Error Adding User to Firestore");
      setLoading(false);
      return;
    }

    const id = new FormData();
    id.append("name", name);
    id.append("email", email);

    for (let pair of id.entries()) {
      console.log(pair[0], pair[1]);
    }

    const formData = new FormData();
    formData.append("language", language);
    formData.append("text", textInput);
    formData.append("speaker_reference_file", audioFile);
    formData.append("speaker_reference_url", "");

    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }

    try {
      const response = await axios.post(
        "https://test.rgenai-azure.devtest.truefoundry.tech/api/tts_inference/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([response.data], { type: "audio/wav" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "output.wav";
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      console.log("Audio File Downloaded!");
      setLoading(false);
      alert("Audio File Downloaded!");
    } catch (error) {
      console.error("Error uploading the file: ", error);
      alert("Error in Generating the Audio");
      setLoading(false);
    }

    window.location.reload();
  };

  return (
    <>
      <div className="demo">
        <div className="demo-left">
          <h1>Ready to Transform Your Creative Journey?</h1>
          <p className="font2">
            You've seen what we can offer. Now it's time to make your choice.
            Don't settle for less when you can have the best.
          </p>
          <button className="demo-button">Get a demo</button>
        </div>
        <div className="demo-right-outer">
          <div className="demo-right-inner">
            <div className="demo-img">
              <img src="/assets/images4.png"></img>
            </div>
          </div>
        </div>
        <br />
      </div>
      {/* <div className="tts">
        <h1 className="demo-heading font1">Try our {props.modelname}</h1>
        <Col xs={24} md={18} lg={16}>
          <p
            className="font2 demo-paragraph"
            style={{ textAlign: "center", color: "#b2b0ba" }}
          >
            Write your creative content and upload a reference audio in WAV
            format. And let our model do the rest. You can see your text to
            speech output in the downloads.
          </p>
        </Col>
        <form onSubmit={handleSubmit}>
          <label>
            <b>Enter Name: </b>
            <b style={{ color: "red" }}>*</b>
            <br />
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              style={{
                marginTop: "10px",
                width: "100%",
                padding: "1%",
              }}
            />
          </label>
          <br />
          <br />
          <label>
            <b>Enter Email: </b>
            <b style={{ color: "red" }}>*</b>
            <br />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{
                marginTop: "10px",
                width: "100%",
                padding: "1%",
              }}
            />
          </label>
          <br />
          <br />
          <label>
            <b>Select Language: </b>
            <b style={{ color: "red" }}>*</b>
            <div style={{ marginTop: "6px" }}>
              <label>
                <input
                  type="radio"
                  value="en"
                  checked={language === "en"}
                  onChange={handleLanguageChange}
                />
                English
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="hi"
                  checked={language === "hi"}
                  onChange={handleLanguageChange}
                />
                Hindi
              </label>
            </div>
          </label>
          <br />
          <label>
            <b>Enter Text: </b>
            <b style={{ color: "red" }}>*</b>
            <br />
            <textarea
              value={textInput}
              onChange={handleTextInputChange}
              rows={4}
              cols={50}
              placeholder="Hello World"
              required
              style={{
                marginTop: "10px",
                width: "100%",
                padding: "1%",
              }}
            />
          </label>
          <br />
          <br />
          <label>
            <b>Upload .wav File:</b>
            <br />
            <input
              type="file"
              accept=".wav"
              onChange={handleFileChange}
              style={{ marginTop: "10px" }}
            />
          </label>
          <br />
          <br />
          <br />
          <center>
            <button
              type="submit"
              style={{
                padding: "4% 5%",
                borderRadius: "10px",
                color: "white",
                backgroundColor: "#357E5E",
                fontSize: "20px",
                fontWeight: "700",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                lineHeight: "normal",
              }}
            >
              Generate Audio
            </button>
            <br />
            {loading && (
              <b style={{ color: "red" }}>Generating Audio. Please Wait!</b>
            )}
          </center>
        </form>
      </div> */}
    </>
  );
}

export default Demo;

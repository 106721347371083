import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';

import {
  RouterProvider,
} from "react-router-dom";

import router from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}>
    <ConfigProvider
      theme={{
        components: {
          Search: {
            colorPrimary: '#357E5E',
            activeBorderColor: '#357E5E',
            algorithm: true, // Enable algorithm
          }
        },
      }}
    >
    </ConfigProvider>
  </RouterProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
